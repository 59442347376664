/**
 * Definition: The state define the reactive module variables
 * Code example:
 * 
 *      export default () => ({
 *          ...
 *      })
 */

export default () => ({
    realEstate: {},
    realEstates: [],
    totalRealEstates: [],
    filters: {
        tiposNegocio: [],
        tipoInmueble: [],
        paises: [],
        departamentos: [],
        ciudades: [],
        estadosPublicacion: [],
        estadosInmueble: [],
    }
});