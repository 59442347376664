/**
 * Definition: The actions are async function that invoke a mutation after resolve async request
 * Code example: 
 * 
 *      export const myAction = async ({ commit }) => {
 *          ...
 *      }
 * 
 */

import realEstateTypesApi from "@/api/realEstateTypesApi";
import axiosInstance from "@/config/axios-conf";

export const loadRealEstateTypes = async ({ commit }) => {
    const res = await axiosInstance.get(`${realEstateTypesApi}/GetTiposInmuebleFront`);

    if (res.status === 200) {
        commit('setRealEstateTypes', res.data);
    }
}
