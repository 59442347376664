/**
 * Definition: The mutations are sync functions to set the state attributes
 * Code example:
 * 
 *      export const myMutation = ( state ) => {
 *          ...
 *      }
 */

export const setUserInfo = (state, user) => {
    state.userInfo = user
}
