/**
 * Definition: The state define the reactive module variables
 * Code example:
 * 
 *      export default () => ({
 *          ...
 *      })
 */

export default () => ({
    cities: []
});