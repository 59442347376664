/**
 * Definition: The actions are async function that invoke a mutation after resolve async request
 * Code example: 
 * 
 *      export const myAction = async ({ commit }) => {
 *          ...
 *      }
 * 
 */

import departmentsApi from "@/api/departmentsApi";
import axiosInstance from "@/config/axios-conf";

export const loadDepartments = async ({ commit }, countryId) => {
    const res = await axiosInstance.get(`${departmentsApi}/GetDepartamentosAllPaisId?PaisId=${countryId}`);

    if (res.status === 200) {
        commit('setDepartmentsTypes', res.data);
    }
}
