/**
 * Definition: Get information from module state and it computed.
 * Code example:
 * 
 *      export const myGetter = ( state ) => {
 *          return state.attr
 *      }
 */

export const getRealEstates = (state) => () => {
    return state.realEstates;
}

export const getRealEstate = (state) => () => {
    return state.realEstate;
}

export const getTotal = (state) => () => {
    return state.totalRealEstates;
}

export const getTiposNegocio = (state) => () => {
    return state.filters.tiposNegocio;
}

export const getTipoInmuebles = (state) => () => {
    return state.filters.tipoInmueble;
}

export const getPaises = (state) => () => {
    return state.filters.paises;
}

export const getDepartamentos = (state) => () => {
    return state.filters.departamentos;
}

export const getCiudades = (state) => () => {
    return state.filters.ciudades;
}

export const getEstadosPublicacion = (state) => () => {
    return state.filters.estadosPublicacion;
}

export const getEstadosInmueble = (state) => () => {
    return state.filters.estadosInmueble;
}
