/**
 * Definition: Get information from module state and it computed.
 * Code example:
 * 
 *      export const myGetter = ( state ) => {
 *          return state.attr
 *      }
 */

export const getRealEstateTypes = (state) => () => {
    return state.realEstateTypes || [];
}
