/**
 * Definition: The mutations are sync functions to set the state attributes
 * Code example:
 * 
 *      export const myMutation = ( state ) => {
 *          ...
 *      }
 */

export const setRealEstates = (state, list) => {
    state.realEstates = list
}

export const setRealEstate = (state, list) => {
    state.realEstate = list.at(0)
}

export const setTotal = (state, value) => {
    state.totalRealEstates = value
}

export const saveFilters = (state, data) => {
    state.filters.tiposNegocio = data[0].tiposNegocio;
    state.filters.tipoInmueble = data[0].tiposInmueble;
    state.filters.paises = data[0].paises;
    state.filters.departamentos = data[0].departamentos;
    state.filters.ciudades = data[0].ciudades;
    state.filters.estadosPublicacion = data[0].estadosPublicacion;
    state.filters.estadosInmueble = data[0].estadosInmueble;
}
