import store from '@/store';
import axios from 'axios'
import Swal from 'sweetalert2';

let requestsCount = 0;
const baseURL = process.env.VUE_APP_BASE_URL;

const axiosInstance = axios.create({
    baseURL: `${baseURL}`
});

axiosInstance.interceptors.request.use((config) => {
    if (requestsCount == 0) {
        Swal.fire({ title: 'Por favor, espere...', allowOutsideClick: false })
        Swal.showLoading()
    }

    requestsCount++;

    return store.getters['userInformation/getAccessToken']
        .then(accessToken => {
            if (accessToken) {
                config.headers.Authorization = `${accessToken}`
            }

            return config
        })
})

axiosInstance.interceptors.response.use((res) => {
    requestsCount--

    if (requestsCount == 0) {
        Swal.close();
    }

    return res
}, ({ response }) => {
    requestsCount--

    if (requestsCount == 0) {
        Swal.close();
    }

    Swal.fire({
        icon: 'error',
        title: 'Ocurrio un error',
        text: determinateError(response)
    })
})

function determinateError(response) {
    // Error cuando no hay conexión o el servidor no genero una response
    if (!response) {
        return 'Ocurrio un error en la comunicación con el servicio, verifique su conexión a internet e intente nuevamente'
    }

    // Error controlado que retorna con un arreglo de tres espacio, solo es relevante el ultimo elemento del arreglo
    if (response.data?.errors?.Error) {
        return response.data.errors.Error.at(-1)
    }

    // Este error se muestra cuando viene un JSON con los errores, los cuales se concatenan para ser mostrados omitiendo sus claves
    if (response.data?.errors) {
        let msg = ''
        Object.keys(response.data?.errors).forEach((key) => {
            msg += response.data?.errors[key][0] || ''
        })
        return msg
    }

    // Este error se muestra cuando viene un Array con los errores
    if (response.data?.Error) {
        let msg = response.data?.Error.at(-1)
        return msg
    }

    // Este error se muestra como variante del error anterior
    if (response?.data) {
        let msg = ''
        Object.keys(response?.data).forEach((key) => {
            msg += response?.data[key][0] || ''
        })
        return msg
    }

    // Retornamos una cadena vacia para evitar errores de undefined
    return ''
}

export default axiosInstance