/**
 * Definition: Get information from module state and it computed.
 * Code example:
 * 
 *      export const myGetter = ( state ) => {
 *          return state.attr
 *      }
 */

import oidcInstante from "@/modules/oidcInstance";

export const getUserInfo = () => () => {
    return oidcInstante.getUser()
        .then(user => {
            return user
        })
}

export const getFullName = () => {
    return oidcInstante.getUser()
        .then(user => {
            return user?.profile?.name
        })
}

export const getAccessToken = () => {
    return oidcInstante.getUser()
        .then(user => {
            return `${user?.token_type} ${user?.access_token}`
        })
}