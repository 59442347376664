/**
 * Definition: The actions are async function that invoke a mutation after resolve async request
 * Code example: 
 * 
 *      export const myAction = async ({ commit }) => {
 *          ...
 *      }
 * 
 */

import bussinesTypesApi from "@/api/bussinesTypesApi";
import axiosInstance from "@/config/axios-conf";

export const loadBussinessTypes = async ({ commit }) => {
    const res = await axiosInstance.get(`${bussinesTypesApi}/GetTiposNegocio`);

    if (res.status === 200) {
        commit('setBussinessType', res.data);
    }
}