/**
 * Definition: The actions are async function that invoke a mutation after resolve async request
 * Code example: 
 * 
 *      export const myAction = async ({ commit }) => {
 *          ...
 *      }
 * 
 */

import oidcInstance from '@/modules/oidcInstance'
import router from '@/router';
import Swal from 'sweetalert2';

export const loginUser = () => {
    Swal.fire({ title: 'Por favor, espere...', allowOutsideClick: false })
    Swal.showLoading()

    window.localStorage.setItem('lastPage', window.location.hash)
    oidcInstance.signinRedirect();
}

export const finishLogin = () => {
    Swal.fire({ title: 'Por favor, espere...', allowOutsideClick: false })
    Swal.showLoading()

    oidcInstance.signinRedirectCallback()
        .then((user) => {
            console.log(user)
        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => {
            Swal.close()

            const lastPage = window.localStorage.getItem('lastPage')?.split('#/')?.at(-1)

            router.push(`${lastPage || '/'}`)
                .then(() => {
                    router.go()
                })
        })
}

export const logoutUser = () => {
    Swal.fire({ title: 'Por favor, espere...', allowOutsideClick: false })
    Swal.showLoading()

    window.localStorage.setItem('lastPage', window.location.hash)
    oidcInstance.signoutRedirect();
}

export const finishLogout = () => {
    Swal.fire({ title: 'Por favor, espere...', allowOutsideClick: false })
    Swal.showLoading()

    oidcInstance.signoutRedirectCallback()
        .then((result) => {
            console.log(result)
        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => {
            Swal.close()

            const lastPage = window.localStorage.getItem('lastPage')?.split('#/')?.at(-1)

            router.push(`${lastPage || '/'}`)
                .then(() => {
                    router.go()
                })
        })
}