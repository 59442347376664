/**
 * Definition: The actions are async function that invoke a mutation after resolve async request
 * Code example: 
 * 
 *      export const myAction = async ({ commit }) => {
 *          ...
 *      }
 * 
 */

import citiesApi from "@/api/citiesApi";
import axiosInstance from "@/config/axios-conf";

export const loadCities = async ({ commit }, deparmentId) => {
    const res = await axiosInstance.get(`${citiesApi}/GetCiudadesAllDepartamentoId?DepartamentoId=${deparmentId}`);

    if (res.status === 200) {
        commit('setCities', res.data);
    }
}