/**
 * Definition: The actions are async function that invoke a mutation after resolve async request
 * Code example: 
 * 
 *      export const myAction = async ({ commit }) => {
 *          ...
 *      }
 * 
 */

import realEstateApi from "@/api/realEstateApi";
import axiosInstance from "@/config/axios-conf";

export const loadRealEstates = async ({ commit }, { filter, paging }) => {

    let params = '';

    if (filter.cod) {
        params += `&Codigo=${filter.cod}&`;
    }

    if (filter.managerUserId) {
        params += `&GestorInmuebleId=${filter.managerUserId}`;
    }

    if (filter.bussinesTypes) {
        params += `&TipoNegocioId=${filter.bussinesTypes}`;
    }

    if (filter.realEstateType) {
        params += `&TipoInmuebleId=${filter.realEstateType}`;
    }

    if (filter.departments) {
        params += `&DepartamentoId=${filter.departments}`;
    }

    if (filter.country) {
        params += `&PaisId=${filter.country}`;
    }

    if (filter.cities) {
        params += `&CiudadId=${filter.cities}`;
    }

    if (filter.orderBy) {
        params += `&Ordenamiento=${filter.orderBy}`;
    }

    if (filter.priceStart) {
        params += `&PrecioInicial=${filter.priceStart}`
    }

    if (filter.priceEnd) {
        params += `&PrecioFinal=${filter.priceEnd}`
    }

    if (paging.page) {
        params += `&Page=${paging.page}`
    }

    if (paging.pageSize) {
        params += `&PageSize=${paging.pageSize}`
    }

    const res = await axiosInstance.get(`${realEstateApi}/InmuebleListadoFront?${params}`);

    if (res.status === 200) {
        commit('setRealEstates', res.data.results);
        commit('setTotal', res.data.totalCount);
    }
}

export const loadRealEstatesByCod = async ({ commit }, { cod }) => {

    let params = `&Codigo=${cod}&Page=${1}&PageSize=${1}&Ordenamiento=0`;

    const res = await axiosInstance.get(`${realEstateApi}/InmuebleListadoFront?${params}`);

    if (res.status === 200) {
        commit('setRealEstate', res.data.results);
    }
}

export const loadFilters = async ({ commit }) => {
    const result = await axiosInstance.get(`${realEstateApi}/InmuebleListadoMaestras`);

    if (result.status === 200) {
        commit("saveFilters", result.data)
    }
}

export const loadRealEstateDetails = async (_, id) => {
    return await axiosInstance.get(`${realEstateApi}/InmuebleDetalleFront?Id=${id}`);
}

export const getBrochure = async (_, codigo) => {
    return await axiosInstance.get(`${realEstateApi}/GetInmuebleCodigoBrochure?codigo=${codigo}`);
}

export const sendContactManager = async (_, {
    nombreCompleto,
    celular,
    email,
    mensaje,
    codigo,
}) => {
    return await axiosInstance.get(`${realEstateApi}/GetInmuebleContactarGestor?NombreCompleto=${nombreCompleto}&Celular=${celular}&Email=${email}&Codigo=${codigo}&Mensaje=${mensaje}`);
}

export const sendContactForm = async (_, {
    nombreCompleto,
    celular,
    email,
    barrio,
    tipoInmueble,
}) => {
    return await axiosInstance.get(`${realEstateApi}/GetInmuebleContactarme?NombreCompleto=${nombreCompleto}&Celular=${celular}&Email=${email}&TipoInmueble=${barrio}&Barrio=${tipoInmueble}`);
}

export const suscribe = async (_, email) => {
    return await axiosInstance.post('/Suscripcion', {
        "correoElectronico": email
    });
}