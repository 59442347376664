export default {
    component: () => import(/* webpackChunkName: "DashboardLayout" */ '@/modules/shared/layouts/LocativeServicesLayout.vue'),
    children: [
        {
            path: 'locativos',
            name: 'locative-services',
            component: () => import(/* webpackChunkName: "LocativeServicesPage.vue" */ '../pages/LocativeServicesPage.vue'),
        },
        {
            path: 'locativo',
            name: 'locative-service',
            component: () => import(/* webpackChunkName: "LocativeServicePage.vue" */ '../pages/LocativeServicePage.vue'),
        },
        {
            path: 'locativo/proveedores',
            name: 'locative-service-providers',
            component: () => import(/* webpackChunkName: "LocativeServicesProvidersPage.vue" */ '../pages/LocativeServicesProvidersPage.vue'),
        },
        {
            path: 'locativo/proveedor',
            name: 'locative-service-provider',
            component: () => import(/* webpackChunkName: "LocativeServicesProviderPage.vue" */ '../pages/LocativeServiceProviderPage.vue'),
        },
        {
            path: 'unete-como-proveedor',
            name: 'join-us-supplier',
            component: () => import(/* webpackChunkName: "JoinPage.vue" */ '../pages/JoinPage.vue'),
        }
        
    ]
}