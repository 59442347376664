import { createRouter, createWebHashHistory } from 'vue-router';

import realEstateRouter from '@/modules/real-estate/router';
import locativeServicesRouter from '@/modules/locative-services/router';

const routes = [
    {
        path: '/',
        redirect: { name: 'home' }
    },
    {
        path: '/',
        component: () => import(/* webpackChunkName: "DashboardLayout" */ '@/modules/shared/layouts/DashboardLayout.vue'),
        children: [
            {
                path: 'inicio',
                name: 'home',
                component: () => import(/* webpackChunkName: "HomePage" */ '@/modules/shared/pages/HomePage.vue'),

            }
        ]
    },
    {
        path: '/ingresar',
        component: () => import(/* webpackChunkName: "DashboardLayout" */ '@/modules/shared/layouts/EmptyLayout.vue'),
        children: [
            {
                path: '',
                name: 'sign-in-up',
                component: () => import(/* webpackChunkName: "HomePage" */ '@/modules/shared/pages/LoginPage.vue'),
            }
        ]
    },
    {
        path: '/bienes-raices',
        component: () => import(/* webpackChunkName: "DashboardLayout" */ '@/modules/shared/layouts/LandingLayout.vue'),
        children: [
            {
                path: '',
                name: 'home-houses',
                component: () => import(/* webpackChunkName: "HomePage" */ '@/modules/shared/pages/HomeHousesPage.vue'),

            }
        ]
    },
    {
        path: '/inmueble',
        ...realEstateRouter
    },
    {
        path: '/contacto',
        component: () => import(/* webpackChunkName: "DashboardLayout" */ '@/modules/shared/layouts/LandingLayout.vue'),
        children: [
            {
                path: '',
                name: 'contact',
                component: () => import(/* webpackChunkName: "ContactPage" */ '@/modules/shared/pages/ContactPage.vue'),

            }
        ]
    },
    {
        path: '/quienes-somos',
        component: () => import(/* webpackChunkName: "DashboardLayout" */ '@/modules/shared/layouts/LandingLayout.vue'),
        children: [
            {
                path: '',
                name: 'who-we-are',
                component: () => import(/* webpackChunkName: "ContactPage" */ '@/modules/shared/pages/WhoWeArePage.vue'),

            }
        ],
    },
    {
        path: '/desde-el-exterior',
        component: () => import(/* webpackChunkName: "DashboardLayout" */ '@/modules/shared/layouts/LandingLayout.vue'),
        children: [
            {
                path: '',
                name: 'from-outside',
                component: () => import(/* webpackChunkName: "FromOutsidePage" */ '@/modules/shared/pages/FromOutsidePage.vue'),

            }
        ],
    },
    {
        path: '/signin-oidc',
        component: () => import(/* webpackChunkName: "DashboardLayout" */ '@/modules/shared/layouts/LandingLayout.vue'),
        children: [
            {
                path: '',
                name: 'finish-login',
                component: () => import(/* webpackChunkName: "FromOutsidePage" */ '@/modules/shared/pages/FinishLogin.vue'),

            }
        ],
    },
    {
        path: '/signout-callback-oidc',
        component: () => import(/* webpackChunkName: "DashboardLayout" */ '@/modules/shared/layouts/LandingLayout.vue'),
        children: [
            {
                path: '',
                name: 'finish-logout',
                component: () => import(/* webpackChunkName: "FromOutsidePage" */ '@/modules/shared/pages/FinishLogout.vue'),

            }
        ],
    },
    {
        path: '/servicios',
        ...locativeServicesRouter
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "NotFound" */ '@/modules/shared/pages/PageNotFound.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;
