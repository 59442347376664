import { createStore } from "vuex";

import bussinesTypesModule from '@/modules/stores/BussinesTypes';
import citiesModule from '@/modules/stores/Cities';
import departmentsModule from '@/modules/stores/Departments';
import realEstateTypesModule from '@/modules/stores/RealEstateTypes';
import realEstateModule from '@/modules/stores/RealEstate';
import userInformationModule from "@/modules/stores/UserInformation";

const store = createStore({
    modules: {
        bussinesTypes: bussinesTypesModule,
        cities: citiesModule,
        departments: departmentsModule,
        realEstateTypes: realEstateTypesModule,
        realEstate: realEstateModule,
        userInformation: userInformationModule
    }
});

export default store;