// import { createOidcAuth, SignInType } from 'vue-oidc-client/vue3';
import oidc from 'oidc-client';

const {
    VUE_APP_IDENTITY_SERVER: identityServer,
    VUE_APP_CLIENT_SECRET: client_secret,
    VUE_APP_RESPONSE_TYPE: response_type,
    VUE_APP_SCOPE: scope,
    VUE_APP_REDIRECT_URI: redirect_uri,
    VUE_APP_POST_LOGOUT_REDIRECT_URI: post_logout_redirect_uri,
} = process.env

const config = {
    userStore: new oidc.WebStorageStateStore({ store: window.localStorage }),
    authority: identityServer,
    client_id: 'VueJS',
    redirect_uri: `${window.location.origin}/#/${redirect_uri}`,
    client_secret,
    response_type,
    scope,
    post_logout_redirect_uri: `${window.location.origin}/#/${post_logout_redirect_uri}`,
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: true,
}

var oidcInstante = new oidc.UserManager({
    ...config,
});

export default oidcInstante