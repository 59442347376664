export default {
    component: () => import(/* webpackChunkName: "DashboardLayout" */ '@/modules/shared/layouts/LandingLayout.vue'),
    children: [
        {
            path: 'consignar',
            name: 'consign-real-estate',
            component: () => import(/* webpackChunkName: "ConsignPage" */ '../pages/ConsignPage.vue'),
        },
        {
            path: 'buscar',
            name: 'seek-real-estate',
            component: () => import(/* webpackChunkName: "SeekPage" */ '../pages/SeekPage.vue'),
        },
        {
            path: 'detalle/:id?',
            name: 'detail-id-real-estate',
            component: () => import(/* webpackChunkName: "DetailPage" */ '../pages/DetailPage.vue'),
        },
        {
            path: 'inversiones',
            name: 'investments-real-estate',
            component: () => import(/* webpackChunkName: "InvestmentsPage" */ '../pages/InvestmentsPage.vue'),
        }
    ]
}